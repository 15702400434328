import React, { Component } from 'react';
import Terminos from '../components/Terminos';

class TerminosScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Terminos />
    )
  }
}
export default TerminosScreen