import React, { Component, Fragment } from 'react';
import '../styles/Footer.css';

export default class Footer extends Component {
    render() {
        return (
            <Fragment>
                {/* <!-- ==========Newslater-Section========== --> */}
                <footer className="footer-section">
                    <div className="container" style={{maxWidth: "100%"}}>
                        {/* <div className="footer-top">
                            <div className="logo">
                                <a href="index-1.html">
                                    <img src="/assets/images/footer/footer-logo.png" alt="Mis Tickets" />
                                </a>
                            </div>
                            <ul className="social-icons">
                                <li>
                                    <a href="#0">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0" className="active">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <i className="fab fa-pinterest-p"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <i className="fab fa-google"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#0">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        <div className="footer-bottom">
                            <div className="footer-bottom-area">
                                <div className="left">
                                    <p>¿Necesitas ayuda? <a href="mailto:contacto@mistickets.com.mx">Contáctanos</a></p>
                                </div>
                                <ul className="links">
                                    <li>
                                        <a href="/terminos/">Términos y Condiciones</a>
                                    </li>
                                    <li>
                                        <a href="/privacidad/">Aviso de Privacidad</a>
                                    </li>
                                    {/* <li>
                                        <a href="#0">FAQ</a>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                {/* <!-- ==========Newslater-Section========== --> */}
            </Fragment >
        )
    }
}