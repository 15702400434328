import React, { Component } from 'react';
import Privacidad from '../components/Privacidad';

class PrivacidadScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Privacidad />
    )
  }
}
export default PrivacidadScreen