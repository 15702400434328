import React, { Component, Fragment } from "react";
import '../styles/Home.css';

export default class SinAsientos extends Component {

    constructor(props){
        super(props);

        this.state =  {
            cantidadSelected: 1,
            buttonDisabled: false
        }

        this.changeCantidadSelected = this.changeCantidadSelected.bind(this);
        this.addCantidad = this.addCantidad.bind(this);
    }

    changeCantidadSelected = (e) => {
        this.setState({ cantidadSelected: e.target.value })
    }

    addCantidad = () => {
        if (this.state.buttonDisabled) return;

        this.setState({ buttonDisabled: true });
        this.props.onAddCantidad(this.state.cantidadSelected);
        this.setState({ buttonDisabled: false });
    }

    render() {
        return(
            <Fragment>
                <section className="seat-plan-section mb-30-none" style={{padding: "30px 0px"}}>
                    <div className="container" style={{maxWidth: "100%"}}>
                        <div>
                            <div className="section-header-1">
                                <h5 className="title">{this.props.localidadNombre}<span className="view-all"> &gt; </span>{this.props.seccionNombre}</h5>
                                <a href="#0" onClick={this.props.onRegresar} style={{color: "#31d7a9"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i class="flaticon-double-right-arrows-angles"></i></div>regresar</a>
                            </div>
                            <h6 style={{color: "#31d7a9", textTransform: "uppercase", marginBottom: "15px"}}>Agrega tus boletos</h6>
                            <div className="filter-area">
                                <div className="filter-main">
                                    <div className="left col-md-10">
                                        <div className="item">
                                            <span className="show col-md-8">Número de boletos :</span>
                                            <select className="select-bar col-md-3" value={this.state.cantidadSelected} onChange={this.changeCantidadSelected}>
                                                <option value="1" style={{color: "#011c57"}}>1</option>
                                                <option value="2" style={{color: "#011c57"}}>2</option>
                                                <option value="3" style={{color: "#011c57"}}>3</option>
                                                <option value="4" style={{color: "#011c57"}}>4</option>
                                                <option value="5" style={{color: "#011c57"}}>5</option>
                                                <option value="6" style={{color: "#011c57"}}>6</option>
                                                <option value="7" style={{color: "#011c57"}}>7</option>
                                                <option value="8" style={{color: "#011c57"}}>8</option>
                                            </select>
                                        </div>
                                    </div>
                                    <ul className="grid-button tab-menu col-md-2">
                                        <a href="#0" onClick={this.addCantidad} disabled={this.state.buttonDisabled}>
                                            <li style={{minWidth: "150px"}}><i className="fas fa-plus"></i> Agregar</li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}