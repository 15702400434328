import React, { Component, Fragment } from "react";
import '../styles/Home.css';

export default class Terminos extends Component {

    render() {
        return (
            <Fragment>
                <section className="blog-section padding-top padding-bottom">
                    <div className="container">
                        <div className="row justify-content-center mb-30-none">
                            <div className="col-lg-12 mb-50 mb-lg-0">
                                <article>
                                    <div className="post-item post-details">
                                        <div className="post-content">
                                            <div className="content">
                                                <div className="post-header">
                                                    <h4 className="m-title">
                                                        Términos y Condiciones
                                                    </h4>
                                                    {/* <blockquote>Modi perferendis ipsa, dolorum eaque accusantium! Velitlibero fugit dolores repellendus consequatur nisi, deserunt aperiam a ea ex hic, iusto atque, quas. Aliquam rerum dolores saepe sunt, assumenda voluptas.</blockquote> */}
                                                    <p>Mistickets.com.mx es un sistema de venta de boletos electrónicos, con residencia en SM 515 Mz. 11 Lt26, Cancún Quintana Roo, México C.P. 77535, al obtener boletos por mistickets.com.mx estas aceptando los siguientes términos:</p>
                                                    <ol>
                                                        <li>MisTickets no es responsable del recinto, contenido, publicidad de todos los eventos publicados en mistickets.com.mx.</li>
                                                        <li>Todas las ventas que se realicen  a través de mistickets.com.mx, puntos de venta propios o terceros son definitivas, pago en una solo exhibición, no se aceptan cambios, devoluciones, cancelaciones de compra o reembolsos.</li>
                                                        <li>Si sus boletos deben ser retirados en taquillas del recinto  el cliente deberá presentar tarjeta con la que realizo el pago, confirmación de la compra e identificación oficial, en caso que sea un tercero quien retire los boletos deberá presentar  copia de la identificación del titular de la tarjeta con la que se realizo el pago, identificación oficial, confirmación de la compra, carta autorización para recoger los boletos firmada por el titular e identificación del tercero.</li>
                                                        <li>Mis tickets no tiene responsabilidad alguna en caso de que un evento sea cancelado o pospuesto, Mis Tickets actuara como intermediario entre los clientes y organizadores de los eventos a fin de que al cliente se le notifique las medidas que tomaran los organizadores de los eventos.</li>
                                                        <li>Mis Tickets no es responsable de los gastos que el cliente pudo generar por la cancelación o aplazamiento de los eventos.</li>
                                                        <li>Los reembolsos solo se realizaran para los eventos cancelados o pospuestos, en todos los casos cuando un boleto es reembolsado, solo se reembolsara el costo del boleto, los cargos por servicio y comisiones generadas por el banco no son reembolsables.</li>
                                                        <li>MisTickets.com.mx opera su venta a través de la terminal de pago de OpenPay, para mayor información consulte https://www.openpay.mx/.</li>
                                                    </ol>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}
