import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import '../styles/Home.css';

export default class Eventos extends Component {

    constructor(props){
        super(props);

        this.state = {
            eventData: [ ],
            isLoaded: false,
            message: ""
        }
    }

    componentDidMount(){
        fetch(`https://v1.mistickets.com.mx/api/v2/eventos`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({
                            eventData: Object.entries(json.data),
                            isLoaded: true
                        });
                    },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        message: error
                    });
                });
            }
        })
        .catch(error => console.error(error));
    }

    render() {
        const data = this.state.eventData.map((element) => {
            return(
            <div key={element[1].id} className="col-sm-6 col-md-4 col-lg-4">
                <div className="event-grid">
                    <div className="movie-thumb c-thumb">
                        <Link to={{ pathname: `/evento/${element[1].id}/` }} onClick={()=>{if(element[1].id == '36'){ alert('Promoci\xF3n 2x1 exclusivo en taquilla del estadio'); }}}>
                            <img src={'./assets/images_mt/eventos/home/' + element[1].website_imagen} alt={element[1].nombre} />
                        </Link>
                    </div>
                    <div className="movie-content bg-one">
                        <h5 className="title m-0">
                            <Link to={{ pathname: `/evento/${element[1].id}/` }} >{element[1].nombre}</Link>
                            <br /><small>{element[1].descripcion}</small>
                        </h5>
                        <div className="movie-rating-percent">
                            <span>{element[1].horario_texto}<br />{element[1].foro_nombre}</span>
                        </div>
                    </div>
                </div>
            </div>
            )
        });

        return (
            <Fragment>
                <section className="banner-section">
                    <div className="banner-bg bg_img" data-background="/assets/images/banner/banner_mt.jpg" style={{backgroundImage: "url('/assets/images/banner/banner_mt.jpg')", opacity: "0.6"}}></div>
                    <div className="container">
                        <div className="banner-content event-content">
                            <h1 className="title bold">compra tus <span className="color-theme">boletos</span></h1>
                            <p>Tu entrada a grandes espectáculos.</p>
                            <span id="eventos"></span>
                        </div>
                    </div>
                </section>
                <section className="event-section" style={{paddingTop: "60px"}}>
                    <div className="section-header-3">
                        <span className="cate">Tu siguiente gran experiencia está en nuestro</span>
                        <h2 className="title">Catálogo de Eventos</h2>
                    </div>
                    <div className="container" style={{maxWidth: "100%"}}>
                        <div className="row flex-wrap-reverse justify-content-center">
                            <div className="col-lg-12 mb-50 mb-lg-0">
                                <div className="filter-tab">
                                    <div className="row mb-10 justify-content-center">
                                        {/* <div key="84" className="col-sm-6 col-md-4 col-lg-4">
                                            <div className="event-grid">
                                                <div className="movie-thumb c-thumb">
                                                    <a onClick={()=>{alert('Boletos disponibles en taquilla del estadio');}}><img src='./assets/images_mt/eventos/home/e0084.png' alt="Cancun FC vs America" /></a>
                                                </div>
                                                <div className="movie-content bg-one">
                                                    <h5 className="title m-0">
                                                        Cancun FC vs America
                                                        <br /><small>Partido Amistoso</small>
                                                    </h5>
                                                    <div className="movie-rating-percent">
                                                        <span>Lunes 13 de Junio del 2022 19:00 hrs.<br />Estadio Andrés Quintana Roo</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {this.state.isLoaded ? data : <div></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="speaker-section padding-bottom padding-top">
                    <div className="container" style={{maxWidth: "100%"}}>
                        <div className="section-header-3">
                            <span className="cate">muchas gracias</span>
                            <h2 className="title">a nuestros patrocinadores</h2>
                            <p>Para tu mayor comodidad visita nuestros puntos de venta donde tendremos el placer de atenderte.</p>
                        </div>
                        <div className="speaker--slider">
                            <div className="speaker-slider owl-carousel owl-theme">
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/altai.png" alt="ALTAI" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/michetanque.png" alt="MicheTanque" />
                                        </a>
                                    </div>
                                    <div className="speaker-content">
                                        <h5 className="title">
                                            <a href="#0">
                                                PUNTO DE VENTA OFICIAL
                                            </a>
                                        </h5>
                                        <span></span>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/kaalau.png" alt="KAALAU" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/nuskah.png" alt="NUS-KAH" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/nuncamerlot.png" alt="Nunca Merlot Teatro" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/medident.png" alt="MediDent" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/jarochitos.png" alt="Los Jarochitos" />
                                        </a>
                                    </div>
                                </div>
                                <div className="speaker-item">
                                    <div className="speaker-thumb" style={{WebkitMask: "none", mask: "none"}}>
                                        <a href="#0">
                                            <img src="./assets/images_mt/pdvs/aqr.png" alt="Estadio Andrés Q.R." />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="speaker-prev">
                                <i className="flaticon-double-right-arrows-angles"></i>
                            </div>
                            <div className="speaker-next">
                                <i className="flaticon-double-right-arrows-angles"></i>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}
