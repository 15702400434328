import React, { Component } from 'react';
import Evento from '../components/Evento';

class EventoScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Evento />
    )
  }
}
export default EventoScreen