import React, { Component, Fragment } from "react";
import '../styles/Home.css';

export default class Localidades extends Component {

    constructor(props){
        super(props);
        this.onLocalidadSelected = this.onLocalidadSelected.bind(this);
        this.onLocalidadHover = this.onLocalidadHover.bind(this);
        this.onLocalidadOut = this.onLocalidadOut.bind(this);
    }

    onLocalidadSelected = (e) => {
        this.props.onLocalidadSelected(e.target.getAttribute("id"));
    }

    onLocalidadHover = (e) => {
        this.props.onLocalidadHover(e.target.getAttribute("id"));
    }

    onLocalidadOut = (e) => {
        this.props.onLocalidadHover(null);
    }

    render() {
        const {
            localidadesData
        } = this.props;

        const data = localidadesData.map((element) => {
            return(
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{padding: "0px 5px"}} key={element.id}>
                    <button className="stat-item justify-content-center" style={{padding: "0px", minHeight: "60px", alignItems: "center"}} id={element.id} onClick={this.onLocalidadSelected} onMouseEnter={this.onLocalidadHover} onMouseOut={this.onLocalidadOut}>
                        <div className="stat-content" style={{width: "initial", paddingLeft: "0px", pointerEvents: "none"}}>
                            <h5>{element.nombre}</h5>
                            {(element.id === 31) ?
                                <span className="info">$ 2,800.00 (x8)</span> :
                                <span className="info">$ {(Math.round(element.total * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                            }
                        </div>
                    </button>
                </div>
            )
        });

        return (
            <Fragment>
                <div className="event-facility" style={{padding: "30px 0px"}}>
                    <div className="container" style={{maxWidth: "100%"}}>
                        {/* <div className="section-header-1">
                            <h5 className="title">localidades</h5>
                        </div> */}
                        <h6 style={{color: "#31d7a9", textTransform: "uppercase", marginBottom: "15px"}}>Selecciona una localidad</h6>
                        <div className="row justify-content-left mb-30-none">
                            {data}
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}