import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route } from "react-router-dom";
import EventosScreen from './screens/EventosScreen';
import EventoScreen from './screens/EventoScreen';
import PrivacidadScreen from './screens/PrivacidadScreen';
import TerminosScreen from './screens/TerminosScreen';
import VentaCompletadaScreen from './screens/VentaCompletadaScreen';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Route path='/' exact component={() => <EventosScreen />} />
        <Route path='/evento/:eventoId/' exact component={() => <EventoScreen />} />
        <Route path='/evento/:eventoId/:token/' exact component={() => <EventoScreen />} />
        <Route path='/pago/:eventoId/' exact component={() => <VentaCompletadaScreen />} />
        <Route path='/terminos/' exact component={() => <TerminosScreen />} />
        <Route path='/privacidad/' exact component={() => <PrivacidadScreen />} />
      </BrowserRouter>
    );
  }
}

export default App;
