import React, { Component, Fragment } from "react";
import '../styles/Home.css';
import {withRouter} from 'react-router';

class VentaCompletada extends Component {
    constructor(props){
        super(props);

        this.state = {
            eventoId: this.props.match.params.eventoId,
            eventoData: [],
            chargeData: []
        }

        this.loadEvento = this.loadEvento.bind(this);
        this.loadCharge = this.loadCharge.bind(this);
    }

    loadEvento = () => {
        fetch(`https://v1.mistickets.com.mx/api/v2/eventos/${this.state.eventoId}`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({
                            eventoData: json.data
                        }, () => {
                            const dateHorario = new Date(this.state.eventoData.horario);
                            const scriptTag = document.createElement("script");
                            scriptTag.innerText = "$('.countdown').countdown({date: '" + dateHorario.toLocaleString("en-US") + "',offset: -5,day: 'Día',days: 'Días'}, function () { alert('El evento ya inició!'); });";
                            scriptTag.async = false;
                            document.body.appendChild(scriptTag);
                            this.loadCharge();
                        });
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    loadCharge = () => {
        const params = new URLSearchParams(this.props.location.search);
        fetch(`https://v1.mistickets.com.mx/api/v2/ventas/${this.state.eventoId}/procesar_pago/${params.get('id')}`, {
            method: "GET"
        })
        .then(response => {
            if (response.ok) {
                response.json().then(
                    (json) => {
                        this.setState({ chargeData: json.data });
                    },
                    (error) => {
                        this.setState({
                            message: error
                        });
                    }
                );
            }
        })
        .catch(error => console.error(error));
    }

    componentDidMount(){
        this.loadEvento();
    }

    render() {
        const {
            eventoData
        } = this.state;

        return (
            <Fragment>
                <div>
                    <section className="speaker-banner bg_img" data-background={'/assets/images_mt/eventos/banners/' + eventoData.website_imagen} style={{backgroundImage: "url('/assets/images_mt/eventos/banners/" + eventoData.website_imagen + "')"}}>
                        <div className="container" style={{maxWidth: "100%"}}>
                            <div className="speaker-banner-content">
                                <h3 className="title">{eventoData.nombre}</h3>
                                <div className="tags">{eventoData.descripcion}<br />{eventoData.ciudad_estado}</div>
                                <div className="social-and-duration">
                                    <div className="duration-area">
                                        <div className="item">
                                            <i className="fas fa-calendar-alt" style={{paddingRight: "10px"}}></i><span>{eventoData.horario_texto}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="tags"><span></span></div>
                                <div className="tags">
                                    <ul className="justify-content-center countdown" style={{paddingTop: "20px"}}>
                                        <li>
                                            <h2><span className="days">00</span></h2>
                                            <p className="days_text">días</p>
                                        </li>
                                        <li>
                                            <h2><span className="hours">00</span></h2>
                                            <p className="hours_text">hrs</p>
                                        </li>
                                        <li>
                                            <h2><span className="minutes">00</span></h2>
                                            <p className="minu_text">min</p>
                                        </li>
                                        <li>
                                            <h2><span className="seconds">00</span></h2>
                                            <p className="seco_text">seg</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="speaker-single padding-bottom pt-lg-0">
                        <div className="container" style={{maxWidth: "100%"}}>
                            <div className="row justify-content-center">
                                <div className="bg-six col-sm-6">
                                    <div className="event-facility" style={{padding: "30px 0px"}}>
                                        <div className="container" style={{maxWidth: "100%"}}>
                                            <div className="section-header-3">
                                                {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <h2 className="title" style={{color: "var(--success)"}}>Pago completado</h2> )}
                                                {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <span className="cate" style={{color: "var(--white)"}}>!Gracias por tu compra!</span> )}
                                                {( this.state.chargeData["\u0000*\u0000status"] === "failed" && <h2 className="title" style={{color: "var(--danger)"}}>Pago declinado</h2> )}
                                                {( this.state.chargeData["\u0000*\u0000status"] !== "completed" && this.state.chargeData["\u0000*\u0000status"] !== "failed" && <h2 className="title" style={{color: "var(--warning)"}}>Pago en proceso</h2> )}
                                            </div>
                                            <div className="widget-1 widget-trending-search">
                                                <div className="widget-1-body">
                                                    <ul>
                                                        {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <li>
                                                            <p>Tus boletos serán enviados a tu correo en un máximo de 4 horas una vez que tu pago ha sido validado. Te recordamos, en caso de que no los recibas, revisar tu carpeta de correos "No deseados" o "Spam". Si tienes cualquier problema, no dudes en contactarnos en <a href="mailto:contacto@mistickets.com.mx" style={{color: "#31d7a9"}}>contacto@mistickets.com.mx</a></p>
                                                        </li> )}
                                                        {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <li>
                                                            <h6 className="sub-title">
                                                                <a href="#0" style={{cursor: "default", color: "var(--success)"}}>número de autorización</a>
                                                            </h6>
                                                            <p>{this.state.chargeData["\u0000*\u0000authorization"]}</p>
                                                        </li> )}
                                                        {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <li>
                                                            <h6 className="sub-title">
                                                                <a href="#0" style={{cursor: "default"}}>descripción</a>
                                                            </h6>
                                                            <p>{this.state.chargeData["\u0000*\u0000serializableData"]?.description}</p>
                                                        </li> )}
                                                        {( this.state.chargeData["\u0000*\u0000status"] === "completed" && <li>
                                                            <h6 className="sub-title">
                                                                <a href="#0" style={{cursor: "default"}}>monto</a>
                                                            </h6>
                                                            <p>$ {(Math.round(this.state.chargeData["\u0000*\u0000serializableData"]?.amount * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} {this.state.chargeData["\u0000*\u0000currency"]}</p>
                                                        </li> )}
                                                        {( this.state.chargeData["\u0000*\u0000status"] === "failed" && <li>
                                                            <h6 className="sub-title">
                                                                <a href="#0" style={{cursor: "default"}}>estado del pago</a>
                                                            </h6>
                                                            <p>{this.state.chargeData["\u0000*\u0000serializableData"]?.error_message}</p>
                                                        </li> )}
                                                    </ul>
                                                </div>
                                                <a href="/" style={{color: "#31d7a9", paddingTop: "30px"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i class="flaticon-double-right-arrows-angles"></i></div>Seguir comprando</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/* <section className="event-details padding-bottom padding-top">
                    <div className="container">
                        <div className="section-header-3">
                            <span className="cate">Algunos de nuestros</span>
                            <h2 className="title">Patrocinadores</h2>
                        </div>
                        <div className="tabTwo sponsor-tab">
                            <div className="tab-area">
                                <div className="tab-item active">
                                    <div className="owl-theme owl-carousel sponsor-slider">
                                        <div className="sponsor-thumb">
                                            <a href="#0">
                                                <img src="./assets/images/sponsor/1.png" alt="sponsor" />
                                            </a>
                                        </div>
                                        <div className="sponsor-thumb">
                                            <a href="#0">
                                                <img src="./assets/images/sponsor/2.png" alt="sponsor" />
                                            </a>
                                        </div>
                                        <div className="sponsor-thumb">
                                            <a href="#0">
                                                <img src="./assets/images/sponsor/3.png" alt="sponsor" />
                                            </a>
                                        </div>
                                        <div className="sponsor-thumb">
                                            <a href="#0">
                                                <img src="./assets/images/sponsor/4.png" alt="sponsor" />
                                            </a>
                                        </div>
                                        <div className="sponsor-thumb">
                                            <a href="#0">
                                                <img src="./assets/images/sponsor/5.png" alt="sponsor" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </Fragment>
        );
    }
}

export default withRouter(VentaCompletada);