import React, { Component, Fragment } from "react";
import '../styles/Home.css';

export default class Secciones extends Component {

    constructor(props){
        super(props);
        this.onSeccionSelected = this.onSeccionSelected.bind(this);
        this.onSeccionHover = this.onSeccionHover.bind(this);
        this.onSeccionOut = this.onSeccionOut.bind(this);
    }

    onSeccionSelected = (e) => {
        this.props.onSeccionSelected(e.target.getAttribute("id"));
    }

    onSeccionHover = (e) => {
        this.props.onSeccionHover(e.target.getAttribute("id"));
    }

    onSeccionOut = (e) => {
        this.props.onSeccionHover(null);
    }

    render() {
        const {
            seccionesData,
            localidadNombre
        } = this.props;

        const data = seccionesData.map((element) => {
            return(
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3" style={{padding: "0px 5px"}} key={element.id}>
                    <button className="stat-item justify-content-center" style={{padding: "0px", minHeight: "60px", alignItems: "center"}} id={element.id} onClick={this.onSeccionSelected} onMouseEnter={this.onSeccionHover} onMouseOut={this.onSeccionOut}>
                        <div className="stat-content" style={{width: "initial", paddingLeft: "0px", pointerEvents: "none"}}>
                            <h5>{element.nombre}</h5>
                            <span className="info">$ {(Math.round(element.total * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                        </div>
                    </button>
                </div>
            )
        });

        return (
            <Fragment>
                <div className="event-facility" style={{padding: "30px 0px"}}>
                    <div className="container" style={{maxWidth: "100%"}}>
                        <div>
                            <div className="section-header-1">
                                <h5 className="title">{localidadNombre}<span className="view-all"></span></h5>
                                <a href="#0" onClick={this.onSeccionSelected} style={{color: "#31d7a9"}}><div style={{WebkitTransform: "rorate(180deg)", transform: "rotate(180deg)", float: "left", paddingLeft: "10px"}}><i class="flaticon-double-right-arrows-angles"></i></div>ver localidades</a>
                            </div>
                            <h6 style={{color: "#31d7a9", textTransform: "uppercase", marginBottom: "15px"}}>Selecciona una sección</h6>
                            <div className="row justify-content-left mb-30-none">
                                {data}
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}