import React, { Component, Fragment } from 'react';

export default class CheckOutBox extends Component {
    constructor(props){
        super(props);

        this.onRemoveBoleto = this.onRemoveBoleto.bind(this);
        this.onVaciar = this.onVaciar.bind(this);
    }

    onRemoveBoleto = (e) => {
        this.props.onRemoveBoleto(e.target.getAttribute("id"));
    }

    onVaciar = (e) => {
        this.props.onVaciar();
    }

    render() {
        const data = this.props.resumenData.map((element) => {
            return (
                <li key={element.id}>
                    <h6 className="subtitle"><span>{element.localidad_nombre}</span><span>$ {(Math.round((parseFloat(element.final_precio) + parseFloat(element.final_cxs)) * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></h6>
                    <div className="info">
                        <span>{(element.localidad_nombre !== element.seccion_nombre) && (element.seccion_nombre)} {(element.fila_etiqueta != null) && (element.fila_etiqueta + element.asiento_etiqueta)}</span>
                        {( !this.props.showPago && <span><a href="#0" id={element.id} onClick={this.onRemoveBoleto}>Eliminar</a></span> )}
                    </div>
                </li>
            )
        })

        const totalCxs = this.props.resumenData.reduce((totals, u) => totals + parseFloat(u.final_cxs), 0);
        const totalBoletos = this.props.resumenData.reduce((totals, u) => totals + parseFloat(u.final_precio), 0);
        const totalCarrito = totalCxs + totalBoletos;

        if(this.props.resumenData.length > 0){
            return (
                <Fragment>
                    <div>
                        <div className="booking-summery bg-one">
                            <h4 className="title">Carrito de Compra</h4>
                            <ul>
                                {data}
                            </ul>
                            <ul className="side-shape">
                                <li>
                                    <span className="info"><span>Boletos</span><span>$ {(Math.round(totalBoletos * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></span>
                                    <span className="info"><span>Cargo por Servicio</span><span>$ {(Math.round(totalCxs * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></span>
                                </li>
                            </ul>
                        </div>
                        <div className="proceed-area  text-center">
                            <h6 className="subtitle"><span>Total de Compra</span><span>$ {(Math.round(totalCarrito * 100) / 100).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span></h6>
                            {( !this.props.showPago && <button className="custom-button back-button" style={{marginRight: "20px", marginBottom: "20px"}} onClick={() => { if (window.confirm('Perderás tus boletos asignados ¿Estás seguro?')) this.onVaciar() }}>Vaciar Carrito</button> )}
                            {( !this.props.showPago && <button className="custom-button back-button" style={{marginBottom: "20px"}} onClick={this.props.onPagar}>Pagar</button> )}
                        </div>
                    </div>
                </Fragment >
            );
        }
        else {
            return(
                <Fragment>
                    <div>
                        <div className="booking-summery bg-one">
                            <h4 className="title">Carrito de Compra</h4>
                            <ul>
                                <li>Tu carrito está vacío.</li>
                            </ul>
                            <ul className="side-shape">
                                <li><strong>¡No esperes más!</strong> Selecciona tus boletos preferidos ahora que están disponibles.</li>
                            </ul>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }
}