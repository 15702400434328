import React, { Component } from 'react';
import Eventos from '../components/Eventos';

class EventosScreen extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Eventos />
    )
  }
}
export default EventosScreen